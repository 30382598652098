<template>
  <div>
    <router-link :to="{ name: 'admin.amenities.create' }" class="btn btn-theme btn-sm float-end"><i class="lni lni-plus me-2"></i>Add New</router-link>
    <h3>Property Amenities</h3>
    <loading v-if="loading" />
    <div class="card mt-3" v-if="! loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="amenities.length == 0">
              <td colspan="3">There is no data to display</td>
            </tr>
            <tr v-for="(amenity, i) in amenities" :key="`amenity-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ amenity.name }}</td>
              <td align="right">
                <a href="#" @click.prevent="destroy(amenity)" class="text-danger"><i class="lni lni-trash-can me-2"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      amenities: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.axios.get('/api/v1/admin/amenities').then(response => {
        this.amenities = response.data.amenities
        this.loading = false
      })
    },

    destroy(amenity) {
      this.loading = true

      this.axios.delete(`/api/v1/admin/amenities/${amenity.id}`).then(() => {
        this.loading = false
        this.fetch()
        this.$swal({
          title: "Deleted",
          text: "The amenity has been deleted",
          type: 'success'
        })
      })
    }
  }
}
</script>